'use client'
import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import LinkComponent from './LinkComponent'
import LocaleSwitcher from './locale-switcher'
import MobileLocaleSwitcher from './mobile-locale-switcher'
import CurrencyTab from './CurrencyTab'
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import tailwindConfig from '@tailwind.config'
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/navigation'

const Nav = ({ dictionary, lang }) => {
    const currentRoute = usePathname();
    const router = useRouter()
    const [language, setLanguage] = useState(lang)
    const [isOpen, setIsOpen] = useState(false)
    const [url, setUrl] = useState('/')

    useEffect(() => {
        const setupUrl = () => {
            const url = currentRoute
            // console.log(url)
            setUrl(url)
        }

        setupUrl()
        router.refresh()
    }, [currentRoute])

  return (
    <>
        <div className={`sm:block hidden ${(url == '/' || url == '/en' || url == '/th') ? '' : 'bg-maincolor drop-shadow-md'} justify-center text-white text-xl z-[100]`}>
            <div className='2xl:w-8/12 mx-auto flex flex-row justify-between items-center py-2'>
                <div className=''>
                    <LinkComponent href={`/`}>
                        <Image
                            src={'/images/logo.png'}
                            height={36}
                            width={120}
                            // fill={"true"}
                            // priority={"true"}
                            // style={{
                            //     objectFit: 'cover'
                            // }}
                            alt="Banner Image"
                        />
                    </LinkComponent>
                </div>
                <div className='w-2/3 flex flex-row justify-between items-center'>
                    <LinkComponent href={`/properties`} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`}>{dictionary.buy}</LinkComponent>
                    <LinkComponent href={'/properties?saleType=rent'} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} >{dictionary.rent}</LinkComponent>
                    <LinkComponent href={'/new-developments'} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} >{dictionary.newDevelopments}</LinkComponent>
                    <LinkComponent href={'/blogs'} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} >{dictionary.blog}</LinkComponent>
                    <LinkComponent href={'/contact-us'} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} >{dictionary.contactUs}</LinkComponent>
                    <LinkComponent href={'/about'} className={`${url == '/' ? 'hover:text-third' : 'hover:text-gray-400'}`} >{dictionary.about}</LinkComponent>
                    <CurrencyTab title="currency" />
                    <LocaleSwitcher language={language}/>
                </div>
            </div>
        </div>
        {isOpen && (
            <div onClick={() => setIsOpen(!isOpen)} className='fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-50'></div>
        )}
        <div className={`flex sm:hidden ${(url == '/' || url == '/en' || url == '/th') ? '' : 'bg-maincolor drop-shadow-md'} w-full items-center justify-between`}>
            <Link href={`/`} className='w-full'>
                <div className='p-2'>
                    <Image
                        src={'/images/logo.png'}
                        height={48}
                        width={120}
                        // fill={"true"}
                        // priority={"true"}
                        style={{
                            objectFit: 'contain'
                        }}
                        alt="Banner Image"
                    />
                </div>
            </Link>
            <div 
                className='border-[1px] border-light m-2 rounded-md'
                onClick={() => setIsOpen(!isOpen)}
            >
                <IoMenu 
                    color={tailwindConfig.theme.extend.colors.light} 
                    size={48}
                />
            </div>
            {isOpen && (
                <>
                    <div className='absolute top-0 right-0 bg-light min-w-[300px] border-l-[1px] border-b-[1px] rounded-l-md'>
                        <div className='w-full flex justify-between items-center'>
                            <div></div>
                            <div 
                                className='border-[1px] border-maincolor m-2 rounded-md'
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <IoClose 
                                    color={tailwindConfig.theme.extend.colors.maincolor} 
                                    size={48}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col pl-4'>
                            <LinkComponent href={`/properties`} className={`text-maincolor text-xl font-semibold py-2`} >{dictionary.buy}</LinkComponent>
                            <LinkComponent href={`/properties?saleType=rent`} className={`text-maincolor text-xl font-semibold py-2`} >{dictionary.rent}</LinkComponent>
                            <LinkComponent href={`/new-developments`} className={`text-maincolor text-xl font-semibold py-2`} >{dictionary.newDevelopments}</LinkComponent>
                            <LinkComponent href={`/contact-us`} className={`text-maincolor text-xl font-semibold py-2`} >{dictionary.contactUs}</LinkComponent>
                            <LinkComponent href={`/blogs`} className={`text-maincolor text-xl font-semibold py-2`} >{dictionary.blog}</LinkComponent>
                            <LinkComponent href={`/about`} className={`text-maincolor text-xl font-semibold py-2`} >{dictionary.about}</LinkComponent>

                        </div>
                        <div>
                            <MobileLocaleSwitcher language={language} />
                        </div>
                    </div>
                </>
            )}
        </div>

    </>
  )
}

export default Nav