'use client'

import { useState } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { i18n } from '../i18n-config'
import { setCookie, getCookie, getCookies } from 'cookies-next';
import { BiWorld } from "react-icons/bi";
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'

export default function LocaleSwitcher({ language }) {
  const [currentLocale, setCurrentLocale] = useState(language)
  const [selected, setSelected] = useState(language)
  const [isOpen, setIsOpen] = useState(false)
  const pathName = usePathname()

  const redirectedPathName = (locale) => {
    const languages = ['en', 'th', 'cn']

    if (!pathName) return '/'
    if (languages.includes(pathName.split('/')[1])) {
      const segments = pathName.split('/')
      segments[1] = locale
      return segments.join('/')
    } else {
      return `/${locale}` + pathName
    }
  }

  // console.log('hu '+ getCookie('NEXT_LOCALE'))
  // console.log('hu ' + currentLocale)


  const setLocaleCookie = (localeValue) => {
    setCookie('NEXT_LOCALE', localeValue);
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (

    <div className="relative flex flex-row p-4">
            {i18n.locales.map((locale) => {
               if (locale !== 'default') {
                return (
                    <div key={locale} className={`w-full py-2 text-center text-xl rounded-md ${locale == language ? 'bg-maincolor text-light' : 'bg-light text-maincolor border-[1px] border-maincolor'}`}>
                        <Link onClick={() => setLocaleCookie(locale)} href={redirectedPathName(locale)}>{locale.toUpperCase()}</Link>
                    </div>
                ) 
              }
            })}
    </div>
  )
}